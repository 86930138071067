<template>
  <v-container>
    <div id="dataset"></div>
    <v-row class="mt-8 mb-16">
      <v-navigation-drawer 
        v-if="!$vuetify.breakpoint.xsOnly"
        app
        permanent 
        class="py-8"
        clipped
        width="160"
      >
        <v-list nav>
          <v-list-item>
            <v-list-item-content>
              <v-row justify="center">
                <v-list-item-title align="center">バージョン</v-list-item-title>
                <v-col cols="8">
                  <v-select v-model="selectedVersion" :items="versions" outlined hide-details dense @change="onVersionSelected" style="background-color: #f5f5f5;" :menu-props="{ offsetY: true }"></v-select>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-4"></v-divider>
          <v-list-item v-if="!loading">
            <v-row justify="center" class="mt-4">
              <v-btn width="80px" height="64px" :class="{ 'primary': selectedMenu === 'dataset' || highlightedMenuIndex === 0 }" @click="selectedMenu = 'dataset'; scrollToTarget('dataset');">
                <div style="display: flex; flex-direction: column; align-items: center;">
                  <v-icon>mdi-table</v-icon>
                  <span style="margin-top: 4px;">データ</span>
                </div>
              </v-btn>
            </v-row>
          </v-list-item>
          <v-list-item v-if="!loading" class="my-4">
            <v-row justify="center">
              <v-icon>mdi-menu-down</v-icon>
            </v-row>
          </v-list-item>
          <v-list-item v-if="!loading">
            <v-row justify="center">
              <v-btn width="80px" height="64px" :class="{ 'primary': selectedMenu === 'train' || highlightedMenuIndex === 1 }" @click="selectedMenu = 'train'; scrollToTarget('train');" :disabled="noDataset" :style="describeLoading ? 'pointer-events: none;' : ''">
                <div style="display: flex; flex-direction: column; align-items: center;">
                  <v-icon>mdi-book-open-page-variant</v-icon>
                  <span style="margin-top: 4px;">学習</span>
                </div>
              </v-btn>
            </v-row>
          </v-list-item>
          <v-list-item v-if="!loading" class="my-4">
            <v-row justify="center">
              <v-icon>mdi-menu-down</v-icon>
            </v-row>
          </v-list-item>
          <v-list-item v-if="!loading">
            <v-row justify="center">
              <v-btn width="80px" height="64px" :class="{ 'primary': selectedMenu === 'model' || highlightedMenuIndex === 2 }" @click="selectedMenu = 'model'; scrollToTarget('model');" :disabled="trainStatus === 'NotStarted' || noDataset" :style="describeLoading ? 'pointer-events: none;' : ''">
                <div style="display: flex; flex-direction: column; align-items: center;">
                  <v-icon>mdi-brain</v-icon>
                  <span style="margin-top: 4px;">モデル</span>
                </div>
              </v-btn>
            </v-row>
          </v-list-item>
          <v-list-item v-if="!loading" class="my-4">
            <v-row justify="center">
              <v-icon>mdi-menu-down</v-icon>
            </v-row>
          </v-list-item>
          <v-list-item v-if="!loading">
            <v-row justify="center">
                <v-btn width="80px" height="64px" :class="{ 'primary': selectedMenu === 'predict' || highlightedMenuIndex === 3 }" @click="selectedMenu = 'predict'; scrollToTarget('predict');" :disabled="trainStatus !== 'Completed' || noDataset" :style="describeLoading ? 'pointer-events: none;' : ''">
                  <div style="display: flex; flex-direction: column; align-items: center;">
                    <v-icon>mdi-chart-line</v-icon>
                    <span style="margin-top: 4px;">予測</span>
                  </div>
                </v-btn>
            </v-row>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-col cols="12">
        <v-row class="pl-4">
        <div v-if="!$vuetify.breakpoint.xsOnly" class="text-h5">
        <router-link 
          color="primary"
          :to="`/project`"
          style="text-decoration: none"
        >
          プロジェクト
        </router-link>
        </div>
        <v-icon v-if="!$vuetify.breakpoint.xsOnly" left>mdi-chevron-right</v-icon>
        <div class="text-h5 mr-4">{{ projectName }}</div>
        <v-chip :class="getClassByType(class_types[projectClassType])" style="pointer-events: none;" text-color="white" class="mt-1 mr-4" small label>
          <v-icon left color="white" small>{{ getClassificationIcon(class_types[projectClassType]) }}</v-icon>
          {{ class_types[projectClassType] }}
        </v-chip>
      </v-row>
        <v-row v-if="datasets" class="mt-8">
            <v-col>
              <div class="text-h5">
                <v-icon left color="#384048" large>mdi-table</v-icon>
                データ
                <HelpTooltip tooltipText="追加した全てのデータセットの学習状況やモデルの精度、予測の準備状態などを確認することができます。"></HelpTooltip>
              </div>
            </v-col>
            
            
            <v-col cols="12" class="mx-0 text-center">
          <UploadArea ref="uploadArea1" :index="0" @fileUploaded="onFileUploaded" @fileDeleted="onFileDeleted" />
          <action-btn
            color="primary"
            :disabled="loading || !file"
            @click="addDataset"
          >
            アップロード
          </action-btn>
            </v-col>
          <v-col cols="12" v-if="!noDataset && selectedVersion">
            <div class="overflow-auto">
              <v-data-table 
                :headers="headers" 
                :height="calculateTableHeight(1)"
                :fixed-header="true" 
                :items="datasets" 
                hide-default-footer 
                item-key="Version" 
                :items-per-page="-1"
                :sort-desc.sync="sortDesc"
                :sort-by.sync="sortBy"
              >
                  <template v-slot:item="{ item }">
                    <tr :style="{ 'background-color': item.DisplayVersion === selectedVersion ? '#f1f1ba' : 'white'}">
                      <td>
                      {{ item.DisplayVersion }}
                      </td>

                      <td v-if="item.TrainStatus === 'NotStarted'">
                       <v-icon color="grey">mdi-progress-clock</v-icon>
                      </td>
                      <td
                        v-else-if="item.TrainStatus === 'Starting' || item.TrainStatus === 'Creating' || item.TrainStatus === 'Running'">
                      <v-icon color="orange">mdi-progress-clock</v-icon>
                      </td>
                      <td v-else-if="item.TrainStatus === 'Completed'">
                      <v-icon color="#159f13">mdi-check-circle</v-icon>
                      </td>
                      <td v-else-if="item.TrainStatus === 'Failed'">
                      <v-icon color="red">mdi-progress-close</v-icon>
                      </td>

                      <td v-if="!item.Score">
                      --
                      </td>
                      <td v-else-if="Number(item.Score) < 0">
                        <div><span class="font-weight-bold">0</span><span style="font-size: 0.5em">%</span></div>
                      </td>
                      <td v-else>
                        <div><span class="font-weight-bold">{{ Number(item.Score).toFixed(1) }}</span><span style="font-size: 0.5em">%</span></div>
                      </td>

                      <td v-if="item.EndpointStatus === 'Succeeded' && item.DeployStatus === 'Succeeded'">
                      <v-icon color="green">mdi-power</v-icon>
                      </td>
                      <td v-else-if="item.DeployStatus === 'Updating' || item.EndpointStatus === 'Creating'">
                        <v-icon color="orange">mdi-power</v-icon>
                      </td>
                      <td v-else>
                        <v-icon color="#606e7b">mdi-power</v-icon>
                      </td>

                      <td>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="downloadDataset(item.DatasetId)">
                              <v-list-item-title><v-icon left>mdi-table-arrow-down</v-icon>ダウンロード</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteDataset(item.DatasetId, item.DisplayVersion)">
                              <v-list-item-title><v-icon left>mdi-trash-can-outline</v-icon>削除</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
        
        <div id="train" class="mb-16 pb-8"></div>
        <v-row v-if="!loading && !noDataset">
        <v-col>
          <div class="text-h5">
          <v-icon left color="#384048" large>mdi-book-open-page-variant</v-icon>
          学習
          <HelpTooltip tooltipText="予測対象となるカラム名にチェックを入れ、『学習開始』ボタンを押すことで学習を開始できます。"></HelpTooltip>
          </div>
        </v-col>
          <v-col cols="12" v-if="describeLoading">
            <v-sheet color="white" class="pa-8 mt-4">
              <v-row align-content="center" style="height: 240px">
                <v-col cols="12" class="text-center">
                    <div class="text-h3"><v-progress-circular indeterminate class="mr-3 mb-2" size="35"></v-progress-circular>読み込み中</div>
                </v-col>  
              </v-row>
            </v-sheet> 
          </v-col>

          <v-col cols="12" v-else-if="failedDatasetDescribe">
            <v-sheet color="white" class="pa-8 mt-4">
              <v-card class="elevation-0 ma-4" color="#ec9ea0">
                <v-card-title><v-icon left color="#384048">mdi-alert-outline</v-icon>取得失敗</v-card-title>
                <v-card-text>
                   データセットの中身を確認し、再度アップロードを行ってください。
                </v-card-text>
              </v-card>
            </v-sheet> 
          </v-col>

          <v-col cols="12" v-else>
            <v-row v-if="project.Type === 'Forecast'" fluid fill-height align-center justify-center>
                <v-col>
                    <v-row class="ma-0">
                      <v-col class="px-0 pb-0">
                        <span>時間カラム名</span>
                        <HelpTooltip :tooltipText="timeTooltipText"/>
                      </v-col>
                      <v-col class="pr-0 pb-0">
                        <span>予測期間</span>
                        <HelpTooltip :tooltipText="forecastTooltipText" />
                      </v-col>
                    </v-row>      
                    <v-row class="ma-0 mb-4 pt-0">
                      <v-text-field
                        v-model="timeColumnName"
                        inputmode="text"
                        required
                        class="mt-0 mr-4 pt-0"
                        :disabled="isTrainDisabled"
                        :rules="[v => !!v || '必須項目です。', validateTimeColumnName]"
                      >
                      </v-text-field>
                      <v-text-field
                          v-model="forecastHorizon"
                          inputmode="numeric"
                          type="number"
                          :step="1"
                          required
                          class="mt-0 pt-0"
                          :disabled="trainStatus !== 'NotStarted'"
                          :rules="[v => validateForecastHorizon(v) || '1以上' + maxForecastHorizon + '以下の整数を入力してください']"
                          :min="1"
                        >
                        </v-text-field>
                    </v-row>
                </v-col>
              </v-row>
            <div class="overflow-auto">
            <v-data-table 
                :headers="headers2"
                :height="calculateTableHeight(2)"
                :fixed-header="true" 
                :items="describeData" 
                :items-per-page="-1"
                hide-default-footer
                item-key="name" 
                v-model="selectedItems" 
                show-select 
                single-select 
              >
              <div v-for="header in headers2" :key="header.value">
                {{ header.text }}
              </div>
                <template #item.id="{ item }"> 
                  <td style="display: none">{{ item.name }}</td> 
                </template>
                <template #body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.name">
                      <th></th>
                      <td :style="{ 'min-width': '80px' }"><v-simple-checkbox :value="selectedItems.includes(item.name)" :disabled="isTrainDisabled" :input-value="selectedItems.includes(item.name)" color="primary" @input="onSelected(item.name); validateTarget(item.dtype); validateClassificationTarget(item.unique)" ></v-simple-checkbox></td> 
                      <td :style="{ 'min-width': '148px' }">
                        <template v-if="item.name.length > 20">
                          <v-tooltip bottom max-width="100%">
                            <template v-slot:activator="{ on }">
                              <div v-on="on">
                                <span>{{ limitString(item.name, 20) }}</span>
                              </div>
                            </template>
                            <span style="font-size:12px;">{{ item.name }}</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <div>{{ item.name }}</div>
                        </template>
                      </td>
                      <td v-if="['int', 'float'].some(type => item.dtype.includes(type))" :style="{ 'min-width': '148px' }"><v-chip small color="#d4f7d4" style="pointer-events: none; color: #159f13;">数値</v-chip></td>
                      <td v-else-if="['object', 'string','bool'].some(type => item.dtype.includes(type))" :style="{ 'min-width': '148px' }"><v-chip small color="#f9ebdf" style="pointer-events: none; color:#cf7019;">カテゴリ</v-chip></td>
                      <td v-else :style="{ 'min-width': '148px' }">{{ item.dtype }}</td>
                      <td :style="{ 'min-width': '148px' }">{{ item.count }}</td>
                      <td v-if="item.mean || item.mean === 0" :style="{ 'min-width': '148px' }">{{ formatNumber(item.mean) }}</td>
                      <td v-else :style="{ 'min-width': '148px' }"></td>
                      <td v-if="item.std || item.std === 0" :style="{ 'min-width': '148px' }">{{ formatNumber(item.std) }}</td>
                      <td v-else :style="{ 'min-width': '148px' }"></td>
                      <td v-if="item.min || item.min === 0" :style="{ 'min-width': '148px' }">{{ formatNumber(item.min) }}</td>
                      <td v-else :style="{ 'min-width': '148px' }"></td>
                      <td v-if="item['50%'] || item['50%'] === 0" :style="{ 'min-width': '148px' }">{{ formatNumber(item['50%']) }}</td>
                      <td v-else :style="{ 'min-width': '148px' }"></td>
                      <td v-if="item.max || item.max === 0" :style="{ 'min-width': '148px' }">{{ formatNumber(item.max) }}</td>
                      <td v-else :style="{ 'min-width': '148px' }"></td>
                      <td :style="{ 'min-width': '148px' }">{{ item.unique }}</td>
                      <td v-if="typeof item.top === 'number'" :style="{ 'min-width': '148px' }">{{ item.top }}</td>
                      <td v-else-if="typeof item.top === 'string'" :style="{ 'min-width': '148px' }">
                        <template v-if="item.top.length > 20">
                          <v-tooltip bottom max-width="240">
                            <template v-slot:activator="{ on }">
                              <div v-on="on">
                                <span>{{ limitString(item.top, 20) }}</span>
                              </div>
                            </template>
                            <span>{{ item.top }}</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <div>{{ item.top }}</div>
                        </template>
                      </td>
                      <td v-else :style="{ 'min-width': '148px' }"></td>
                      <td v-if="item.freq || item.freq === 0" :style="{ 'min-width': '148px' }">{{ item.freq }}</td>
                      <td v-else :style="{ 'min-width': '148px' }"></td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </div>
          </v-col>
           <action-btn
            v-if="!isShowDescribeTable"
            color="primary"
            :disabled="!selectedItems[0] || trainStatus !== 'NotStarted' || dialogValidateTargetFlg || !isForecastHorizonValid"
            @click="trainDataset"
          >
            <div v-if="trainStatus === 'Completed'">学習済み</div>
            <div v-else-if="trainStatus === 'Failed'">学習失敗</div>
            <div v-else>学習開始</div>
          </action-btn>
        </v-row>
        <v-row v-if="trainStatus === 'Failed' && !describeLoading">
          <v-col>
            <v-alert
              type="error"
            >
            学習に失敗しました。データセットの内容を確認し、データセットの追加からやり直してください。
            </v-alert>
          </v-col>
        </v-row>
        
        <div id="model" class="mb-16 pb-8"></div>
        <v-row class="mt-16" v-if="!loading && !describeLoading && !noDataset && (trainStatus === 'Completed' || trainStatus === 'Starting' || trainStatus === 'Creating' || trainStatus === 'Running')">
          <v-col>
            <div class="text-h5">
              <v-icon left color="#384048" large>mdi-brain</v-icon>
              モデル
              <HelpTooltip tooltipText="学習によって作られたモデルの精度を、表やグラフを用いて確認することができます。表やグラフの特徴と改善点についての文章はChatGPTによって生成されています。"></HelpTooltip>
            </div>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-sheet v-show="modelScore == ''" color="white" class="pa-8 mt-4">
              <v-row align-content="center" style="height: 240px">
                <v-col cols="12" class="text-center">
                    <div v-if="isTrainStatusLoading" class="text-h3"><v-progress-circular indeterminate class="mr-3 mb-2" size="35"></v-progress-circular>学習中</div>
                    <div v-else-if="trainStatus === 'Failed'" class="text-h3"><v-icon x-large color="red" class="pr-2 pb-2">mdi-alert-outline</v-icon>学習失敗</div>
                </v-col>  
              </v-row>
            </v-sheet> 
              <v-row v-show="modelScore != ''" class="ma-0">
                <v-col class="px-0">
                  <v-sheet color="white" class="pa-8">
                      <v-row>
                        <v-col cols="12" md="6" lg="6" align="center">
                          <div class="text-h6">モデルの精度</div>
                          <div v-if="modelScore < 0" class="text-h2"><span class="primary--text font-weight-bold">0</span><span style="font-size: 0.5em">%</span></div>
                          <div v-else class="text-h2"><span class="primary--text font-weight-bold">{{ Number(modelScore).toFixed(1) }}</span><span style="font-size: 0.5em">%</span></div>
                        </v-col>
                        <v-col cols="12" md="6" lg="6" align="center">
                          <div class="text-h6">モデルのダウンロード</div>
                          <v-tooltip bottom color="#e3a76f">
                            <template v-slot:activator="{ on }">
                              <v-btn icon width="80px" height="80px"><v-icon v-on="on" @mouseover="isHovered = true" @mouseleave="isHovered = false" @click="modelDownload" color="#606e7b" size="64">mdi-tray-arrow-down</v-icon></v-btn>
                            </template>
                            <span style="color: #384048;">クリックすると、.pkl形式のファイルをダウンロードします。</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                  </v-sheet>

                  <v-toolbar dense class="elevation-0 mt-4 py-4 px-4">
                    <div v-if="project.Type == 'Classification'" class="text-h5 pl-8">
                      <v-icon left>mdi-matrix</v-icon>
                      混同行列
                      <HelpTooltip tooltipText="混同行列は、多項分類の結果を視覚化するためのツールです。この行列は、モデルが予測したクラスと実際のクラスを比較し、どれだけ正確に予測しているかを示します。混同行列には、主対角線（左上から右下）に沿って青色が濃いセルが配置されます。青いセルが多いほど、正確な予測が増えていることを示します。一方、他のセルは明るい色で示され、これらのセルの色が濃くなると、誤った予測が増えていることを意味します。混同行列を見ることで、モデルがどのクラスをよく予測できているか、またどのクラスで誤った予測が多いかを把握することができます。"></HelpTooltip>
                    </div>
                    <div v-else class="text-h5 pl-8">
                      <v-icon left>mdi-chart-scatter-plot</v-icon>
                      予測値と真値
                      <HelpTooltip tooltipText="予測値と真値のグラフは、予測値と実際の値（真値）を比較するためのツールです。bin_averageは予測値の平均を表し、予測範囲は予測値の誤差範囲を示します。idealの直線は理想的な状態を表し、予測値が完璧に真値に一致する場合を示します。グラフを通じて、予測値が真値に近いか、またどの程度誤差があるかを直感的に理解することができます。"></HelpTooltip>
                    </div>
                    <v-spacer></v-spacer>
                    <v-tooltip  bottom color="#e3a76f">
                      <template v-slot:activator="{ on }">
                        <div class="text-caption">
                          <span v-if="!$vuetify.breakpoint.xsOnly && isModelReportCommentLoading1">取得中</span>
                          <span v-else-if="!$vuetify.breakpoint.xsOnly">コメント再生成</span>
                          <v-btn fab small :loading="isModelReportCommentLoading1" :disabled="isModelReportCommentLoading1" class="ml-2"><v-icon v-on="on" @mouseover="isHovered = true" @mouseleave="isHovered = false" @click="getModelReport(datasetId, true, false)" color="#606e7b" large>mdi-refresh</v-icon></v-btn>
                        </div>
                      </template>
                      <span style="color: #384048;">クリックすると、ChatGPTによるコメントが再生成されます。</span>
                    </v-tooltip>
                  </v-toolbar>
                  <v-sheet color="white">
                    <v-row v-if="project.Type" class="pt-12 pa-8">
                      <v-col cols="12" md="6" lg="6" v-show="project.Type == 'Classification'" style="height:32vmax;">
                      <v-row>
                        <div v-if="confusionMatrixCommentModelFeature && confusionMatrixCommentImprovementPoints && project.Type == 'Classification'" v-show="!$vuetify.breakpoint.mdAndDown" style="display: flex; align-items: center;" class="pt-16">
                            <div style="writing-mode: sideways-lr; transform: rotate(270deg);" class="pt-16 px-auto text-caption">実際のクラス</div>
                        </div>
                        <v-col style="max-height: 400px;" class="overflow-auto">
                          <div v-if="confusionMatrixCommentModelFeature && confusionMatrixCommentImprovementPoints" v-show="!$vuetify.breakpoint.mdAndDown" class="pl-12 pb-2 text-center text-caption">予測のクラス</div>
                          <v-data-table
                            :headers="modelReportHeaders"
                            :items="modelReportitems"
                            :items-per-page="modelReportitemsPerPage"
                            hide-default-footer
                            class="elevation-1 confusion-header"
                            :dense="$vuetify.breakpoint.smAndDown" 
                            :mobile-breakpoint="0"
                          >
                            <template v-for="(header, index) in modelReportHeaders" v-slot:[`item.${header.value}`]="{ item }">
                              <div :style="{
                                'background-color': index === 0 ? '#f9f9fa' : getColor(item[header.value], modelReportitemsMaxValue),
                                'min-width': index === 0 ? '12px' : 'auto',
                              }" class="confusion-cell" v-bind:key="item[header.value]">{{ item[header.value] }}</div>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      </v-col>
                      <v-col cols="12" md="6" lg="6" v-show="project.Type === 'Regression' || project.Type === 'Forecast'">
                        <div style="position: relative; height:32vmax; width:32vmax" class="mx-auto">
                          <canvas id="chartCanvas1" ref="chartCanvas1"></canvas>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="6">
                        <v-row v-if="modelReportNoCommentsText1" style="max-height: 500px;" class="overflow-auto">
                          <v-card class="elevation-0 ma-4" color="#ec9ea0">
                            <v-card-title><v-icon left color="#384048">mdi-alert-outline</v-icon>取得失敗</v-card-title>
                            <v-card-text>
                                {{ modelReportNoCommentsText1 }}
                            </v-card-text>
                          </v-card>
                        </v-row>
                        <v-sheet v-if="isModelReportCommentLoading1">
                          <v-skeleton-loader
                            class="mx-auto my-4"
                            max-width="100%"
                            type="image"
                          ></v-skeleton-loader>
                        </v-sheet>
                        <v-sheet v-if="isModelReportCommentLoading1">
                          <v-skeleton-loader
                            class="mx-auto my-4"
                            max-width="100%"
                            type="image"
                          ></v-skeleton-loader>
                        </v-sheet>
                          <comments-card
                            v-if="!isModelReportCommentLoading1"
                            :isClassification="project.Type === 'Classification'"
                            :modelFeature="confusionMatrixCommentModelFeature"
                            :improvementPoints="confusionMatrixCommentImprovementPoints"
                          />
                          <comments-card
                            v-if="!isModelReportCommentLoading1"
                            :isClassification="project.Type === 'Regression' || project.Type === 'Forecast'"
                            :modelFeature="predictedTrueCommentModelFeature"
                            :improvementPoints="predictedTrueCommentImprovementPoints"
                          />
                      </v-col>
                    </v-row>
                  </v-sheet>
                  <v-toolbar dense class="elevation-0 mt-8 py-4 px-4">
                    <div v-if="project.Type == 'Classification'" class="text-h5 pl-8">
                      <v-icon left>mdi-chart-bell-curve-cumulative</v-icon>
                      ROC曲線
                      <HelpTooltip tooltipText="ROC曲線は、機械学習モデルの性能を評価するためのツールです。この曲線は、異なる閾値での真陽性率（True Positive Rate）と偽陽性率（False Positive Rate）をプロットしたものです。真陽性率は、実際に陽性であるものをモデルが正しく陽性と予測する割合を示します。一方、偽陽性率は、実際には陰性であるものをモデルが誤って陽性と予測する割合を示します。ROC曲線が左上に近いほど、真陽性率が高く、偽陽性率が低い性能の高いモデルを示します。曲線が対角線に近い場合、モデルの性能はランダムな予測と同等です。"></HelpTooltip>
                    </div>
                    <div v-if="project.Type == 'Regression' || project.Type === 'Forecast'" class="text-h5 pl-8">
                      <v-icon left>mdi-chart-bar</v-icon>
                      残差グラフ
                      <HelpTooltip tooltipText="残差グラフは、機械学習のモデルの予測と実際の値の差（残差）を可視化するためのグラフです。横軸には残差の値が、縦軸には残差の頻度（ビンの数）が表示されます。このグラフを通じて、モデルが予測にどれだけ誤差があるかや、どのような傾向があるかを把握できます。誤差の大きさや分布が偏っている場合は、モデルの改善が必要かもしれません。"></HelpTooltip>
                    </div>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="#e3a76f">
                      <template v-slot:activator="{ on }">
                        <div class="text-caption">
                          <span v-if="!$vuetify.breakpoint.xsOnly && isModelReportCommentLoading2">取得中</span>
                          <span v-else-if="!$vuetify.breakpoint.xsOnly">コメント再生成</span>
                          <v-btn fab small :loading="isModelReportCommentLoading2" :disabled="isModelReportCommentLoading2" class="ml-2"><v-icon v-on="on" @mouseover="isHovered = true" @mouseleave="isHovered = false" @click="getModelReport(datasetId, false, true)" color="#606e7b" large>mdi-refresh</v-icon></v-btn>
                        </div>
                      </template>
                      <span style="color: #384048;">クリックすると、ChatGPTによるコメントが再生成されます。</span>
                    </v-tooltip>
                  </v-toolbar> 
                  <v-sheet color="white">
                    <v-row class="pt-12 pa-8">  
                      <v-col cols="12" md="6" lg="6" v-show="project.Type">
                        <div style="position: relative; height:32vmax; width:32vmax" class="mx-auto">
                          <canvas id="chartCanvas2" ref="chartCanvas2"></canvas>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="6">
                        <v-row v-if="modelReportNoCommentsText2" style="max-height: 500px;" class="overflow-auto">
                          <v-card class="elevation-0 ma-4" color="#ec9ea0">
                            <v-card-title><v-icon left color="#384048">mdi-alert-outline</v-icon>取得失敗</v-card-title>
                            <v-card-text>
                                {{ modelReportNoCommentsText2 }}
                            </v-card-text>
                          </v-card>
                        </v-row>
                        <v-sheet v-if="isModelReportCommentLoading2">
                          <v-skeleton-loader
                            class="mx-auto my-4"
                            max-width="100%"
                            type="image"
                          ></v-skeleton-loader>
                        </v-sheet>
                        <v-sheet v-if="isModelReportCommentLoading2">
                          <v-skeleton-loader
                            class="mx-auto my-4"
                            max-width="100%"
                            type="image"
                          ></v-skeleton-loader>
                        </v-sheet>
                        <comments-card
                          v-if="!isModelReportCommentLoading2"
                          :isClassification="project.Type === 'Classification'"
                          :modelFeature="accuracyTableCommentModelFeature"
                          :improvementPoints="accuracyTableCommentImprovementPoints"
                        />
                        <comments-card
                          v-if="!isModelReportCommentLoading2"
                          :isClassification="project.Type === 'Regression' || project.Type === 'Forecast'"
                          :modelFeature="residualsCommentModelFeature"
                          :improvementPoints="residualsCommentImprovementPoints"
                        />
                      </v-col>
                    </v-row>
                  </v-sheet>

                  <v-toolbar v-if="project.Type == 'Forecast'" dense class="elevation-0 mt-8 py-4 px-4">
                      <div class="text-h5 pl-8">
                          <v-icon left>mdi-trending-up</v-icon>
                          予測期間グラフ
                        <HelpTooltip tooltipText="予測期間グラフは、横軸に時間を、縦軸に予測対象の値を表す指標を取ります。このグラフでは、予測値と真値をプロットし、さらに不確実性を示す範囲（PI_upper_boundとPI_lower_bound）も表現されます。これにより、予測の精度と不確実性が視覚的に把握できます。予測値と真値が近い位置にあれば、モデルの正確性が高く、不確実性範囲が狭ければ、予測の信頼性が高いと言えます。一方で、真値が不確実性範囲外にある場合は、精度を向上させる必要があるかもしれません。"></HelpTooltip>
                      </div>
                  </v-toolbar> 
                  <v-sheet v-if="project.Type == 'Forecast'">
                      <v-row class="pt-12 pa-8">  
                        <v-col cols="12" v-show="project.Type" class="overflow-auto">
                          <div style="position: relative; width:70vmax" class="pl-8">
                            <canvas id="chartCanvas3" ref="chartCanvas3"></canvas>
                          </div>
                        </v-col>
                      </v-row>
                  </v-sheet>

                  <v-toolbar v-if="featureImportanceItems.length > 1" dense class="elevation-0 mt-8 py-4 px-4">
                      <div class="text-h5 pl-8">
                          <v-icon left>mdi-trophy-variant</v-icon>
                          特徴量の重要度ランキング
                          <HelpTooltip tooltipText="特徴量の重要度とは、機械学習モデルにおいて、どの特徴量（変数）が予測精度にどれだけ影響を与えるかを数値化した指標のことです。重要な特徴量は、モデルの予測結果に大きく寄与し、その特徴量を適切に理解することでモデルの解釈が容易になります。以下の表では特徴度の重要度が高いものから順に並べています。"></HelpTooltip>
                      </div>
                  </v-toolbar>
 
                  <v-sheet v-if="featureImportanceItems === null" color="white" class="pa-8 mt-4">
                    <v-row align-content="center" style="height: 240px">
                      <v-col cols="12" class="text-center">
                          <div class="text-h3"><v-progress-circular indeterminate class="mr-3 mb-2" size="35"></v-progress-circular>読み込み中</div>
                      </v-col>  
                    </v-row>
                  </v-sheet> 
                  <v-sheet v-else-if="featureImportanceItems.length > 1">
                    <v-row class="pt-12 pa-8">
                      <v-col cols="12" class="pl-8">
                        <v-data-table 
                          :height="calculateTableHeight(2)"
                          :fixed-header="true" 
                          :headers="featureImportanceHeaders" 
                          :items="featureImportanceItems" 
                          hide-default-footer 
                          :items-per-page="-1"
                        >
                          <template v-slot:item="{item}">
                            <tr>
                              <td :style="{ 'width': '10%' }">{{ item.order }}</td>
                              <td :style="{ 'width': '40%' }">
                                <template v-if="item.key.length > 20">
                                  <v-tooltip bottom max-width="100%">
                                    <template v-slot:activator="{ on }">
                                      <div v-on="on">
                                        <span>{{ limitString(item.key, 40) }}</span>
                                      </div>
                                    </template>
                                    <span style="font-size:12px;">{{ item.key }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-else>
                                  <div>{{ item.key }}</div>
                                </template>
                              </td>
                              <td :style="{ 'width': '50%' }"><v-progress-linear :value="item.normalizedValue*100" height="10" color="#1976d2"></v-progress-linear></td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
        <div id="predict" class="mb-16 pb-8"></div>
        <v-row class="mt-16" v-if="!loading && !predictSheetLoading && !noDataset && trainStatus === 'Completed'">
          <v-col>
            <div class="text-h5">
              <v-icon left color="#384048" large>mdi-chart-line</v-icon>
              予測
              <HelpTooltip tooltipText="電源マークを押すと、予測のための準備が開始されます。準備が完了し、稼働中の状態になると、テストデータを入力することで予測を行うことができます。"></HelpTooltip>
            </div>
          </v-col>
            
          <v-col cols="12" class="mb-16">
          <v-row v-if="trainStatus === 'Completed'" fluid fill-height align-center justify-center>
            <v-col>
              <v-sheet>
                <v-tabs v-model="selectedTab" @change="resetPredictionData" color="black">
                  <v-tabs-slider color="transparent"></v-tabs-slider>
                  <v-tab v-for="(tab, index) in tabs" :key="index" :style="{ backgroundColor: selectedTab === index ? 'white' : '#eceeef', pointerEvents: selectedTab === index ? 'none' : 'auto' }">
                    {{ tab }}
                  </v-tab>
                </v-tabs>
              </v-sheet>
              <v-toolbar class="elevation-0 pt-1 px-8">
                <v-row>
                  <v-col cols="12" align="end" class="pa-0">
                    <v-tooltip bottom v-if="endpointStatus === 'Succeeded' && deployStatus === 'Succeeded' && endpointDeletionStatus !== 'Deleting'" color="#e3a76f">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="text-caption">稼働中<v-btn fab small class="ml-2"><v-icon @click="dialogDeleteEndpoint = true" v-bind="attrs" v-on="on" color="green" large>mdi-power</v-icon></v-btn></div>
                      </template>
                      <span style="color: #384048;">クリックすると、予測の稼働を停止するためのポップアップが表示されます。</span>
                    </v-tooltip>
                    <div v-else-if="endpointStatus === 'Deleting' || deployStatus === 'Deleting' || endpointDeletionStatus === 'Deleting'" class="text-caption">停止中<v-btn fab small class="ml-2" disabled style="pointer-events: none;"><v-icon color="grey" large>mdi-power</v-icon></v-btn></div>
                    <v-tooltip bottom v-else-if="trainStatus === 'Completed'" color="#e3a76f">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="text-caption">
                          <span v-if="isDeployStatusLoading">準備中</span>
                          <span v-else-if="endpointStatus === 'NotStarted'">開始</span>
                          <v-btn fab small :loading="isDeployStatusLoading" :disabled="isDeployStatusLoading" class="ml-2"><v-icon @click="deployDataset" v-bind="attrs" v-on="on" color="#606e7b" large>mdi-power</v-icon></v-btn>
                        </div>
                      </template>
                      <span style="color: #384048;">クリックすると、予測のための準備を開始します。</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-toolbar>
              <v-sheet color="white" class="pa-10">
                <v-tabs-items v-model="selectedTab">
                  <v-tab-item v-for="(tab, index) in tabs" :key="index">
                    <v-sheet>
                      <div v-if="index === 0">
                        <v-form ref="predictForm" @submit.prevent="submitData">
                          <v-row :justify="Object.keys(features).length <= 6 ? 'center' : 'start'">
                            <v-col v-for="(value, key) in features" :key="key" cols="12" sm="6" md="3" lg="2">
                              <v-text-field
                                v-model="copiedFeatures[key]"
                                :label="key"
                                :type="getInputType(features[key])"
                                :step="getStep(features[key])"
                                :rules="getRules(features[key])"
                                required
                                :disabled="isPredictDisabled"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </div>
                      <div v-if="index === 1">
                        <UploadArea ref="uploadArea2" :index="1" @fileUploaded="onFileUploaded" @fileDeleted="onFileDeleted" :style="isPredictDisabled ? 'pointer-events: none;' : ''"/>
                      </div>
                    </v-sheet>
                  </v-tab-item>
                </v-tabs-items>
              </v-sheet>  
              <action-btn
                v-if="selectedTab === 0 || (selectedTab === 1 && predictions === null) || (selectedTab === 1 && testData === null)"
                color="primary"
                :disabled="isPredictDisabled || (selectedTab === 1 && testData === null)"
                @click="submitForm"
                class="mt-1"
              >
                予測
              </action-btn>        
                <v-row justify="center" v-if="endpointStatus === 'Succeeded' && deployStatus === 'Succeeded' && endpointDeletionStatus !== 'Deleting' && selectedTab === 0">
                  <v-col v-if="selectedItems[0]" cols="12" align="center">
                    <v-row justify="center" class="text-h6 my-4">予測対象：{{ selectedItems[0] }}</v-row>
                    <v-row justify="center"><span class="text-h3 primary--text font-weight-bold">{{ predictResult }}</span></v-row>
                    <v-row v-if="predictResult!=='--'" justify="center" class="mt-4"><v-btn small @click="copyPredictResult" :disabled="isPredictResultCopied" color="primary">{{ predictResultCopyBtnText }}</v-btn></v-row>
                  </v-col>
                </v-row>
                <v-row v-if="deployStatus === 'NotStarted' && !isDeployStatusLoading">
                  <v-col>
                    <v-alert
                      type="warning"
                    >
                      予測を行う前に、右上の電源マークをクリックして、予測機能を稼働させてください。
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row v-if="deployStatus === 'Updating'">
                  <v-col>
                    <v-alert
                      type="warning"
                    >
                      予測ができるようになるまで、10分程度お待ちください。
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-alert
                      v-if="failedPredictionMessage"
                      type="error"
                    >
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          予測に失敗しました。テストデータの内容を確認してください。
                        </div>
                        <span @click="failedPredictionMessage = false" style="cursor: pointer;">非表示</span>
                      </div>
                    </v-alert>
                    <v-alert
                      v-if="successPredictionMessage"
                      type="success"
                    >
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          予測に成功しました。ダウンロードされたCSVファイルをご確認ください。
                        </div>
                        <span @click="successPredictionMessage = false" style="cursor: pointer;">非表示</span>
                      </div>
                    </v-alert>
                  </v-col>
                </v-row>
            </v-col>
          </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <loading v-if="loading"></loading>
    <loading v-if="pageLoading"></loading>
    <dialog-alert
      v-if="isAddDatasetBlocked"
      :dialog-visible.sync="isAddDatasetBlocked"
      alert-type="warning"
      content="現在のプランでは、これ以上のデータセットを追加することはできません。管理者にお問い合わせください。"
    />

     <dialog-alert
        v-if="dialogValidateTarget"
        :dialog-visible.sync="dialogValidateTarget"
        alert-type="warning"
        :content="`${class_types[project.Type]}では「${validateTargetType}」は、予測対象に選択できません。`"
      />

      <dialog-alert
        v-if="validateClassificationTargetAlert"
        :dialog-visible.sync="validateClassificationTargetAlert"
        alert-type="warning"
        content="一意な要素数が1つのため、予測対象に選択できません。"
      />

    <v-dialog
      v-if="dialogDeleteEndpoint"
      v-model="dialogDeleteEndpoint"
      max-width="600"
    >
      <v-card class="text-center" color="white">
      <v-card-title class="justify-center">
        予測の稼働を停止しますか？
      </v-card-title>
      <v-card-text class="mt-4 pb-0">
        <v-row>
          <v-col cols="12" class="pb-0">
            <p>
              <span style="color: red;">再度予測が可能になるまでには、1時間程度かかることがあります。</span>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
          <v-col cols="5">
            <v-btn
              block
              outlined
              @click="dialogDeleteEndpoint = false"
            >キャンセル
            </v-btn>
          </v-col>
          <v-col cols="5">
            <v-btn 
              color="red"
              class="white--text"
              block
              @click="deleteEndpoint();
              dialogDeleteEndpoint = false"
              >停止
            </v-btn>
          </v-col>
      </v-card-actions>
    </v-card>
    </v-dialog>

      <v-dialog v-if="dialogProhibitLeavingPage" v-model="dialogProhibitLeavingPage" persistent max-width="800">
        <v-alert :value="true" type="warning" class="mb-0">
          画面操作をせずに、そのままの状態で1～2分お待ちください。
            <v-progress-linear :indeterminate="true" color="#fb8c00" class="mt-4"></v-progress-linear>
        </v-alert>
      </v-dialog>

      <v-dialog
        v-if="dialogDeleteDataset"
        v-model="dialogDeleteDataset"
        max-width="600"
      >
      <DialogDelete 
          v-on:closeDeleteEmit="dialogDeleteDataset = false"
          v-on:deleteItemExeEmit="deleteItemExe"
          :name="deleteDatasetVersion"
          :deletionType="deleteDatasetId"
          :deletionTitle="deletionTitle"
        />
      </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import Papa from "papaparse";
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Chart from 'chart.js/auto';
import commonMixin from '@/mixins/commonMixin.js';

const ActionBtn = () =>
  import("@/components/ActionBtn.vue");
const CommentsCard = () =>
  import("@/components/CommentsCard.vue");
const DialogAlert = () =>
  import("@/components/DialogAlert.vue");
const DialogDelete = () =>
  import("@/components/DialogDelete.vue");  
const Loading = () =>
  import("@/components/Loading.vue");
const HelpTooltip = () =>
  import("@/components/HelpTooltip.vue");
const UploadArea = () =>
  import("@/components/UploadArea.vue");
export default {
  components: {ActionBtn, CommentsCard, DialogAlert, DialogDelete, Loading, HelpTooltip, UploadArea },
  mixins: [validationMixin, commonMixin],
  validations: {
    files: { required },
  },
  data() {
    return {
      hasDatasetPageBeenDisplayed: false,
      scrollPosition: 0,
      highlightMenu: [
        { id: 'dataset', isHighlighted: false },
        { id: 'train', isHighlighted: false },
        { id: 'model', isHighlighted: false },
        { id: 'predict', isHighlighted: false },
      ],
      selectedMenu: '',
      highlightedMenuIndex: 0,
      target:"",
      timeColumnName:"",
      maxForecastHorizon: 0,
      forecastHorizon:"",
      dialogDeleteDataset:false,
      dialogValidateTarget:false,
      dialogValidateTargetFlg: false,
      validateClassificationTargetAlert:false,
      validateTargetType:"",
      dialogProhibitLeavingPage:false,
      deleteDatasetVersion:"",
      deleteDatasetId:"",
      deletionTitle:"",
      dialogDeleteEndpoint:false,
      loading: false,
      pageLoading: false,
      describeLoading: false,
      failedDatasetDescribe: false,
      predictSheetLoading: false,
      isShowDescribeTable: false,
      isTrainStatusLoading: false,
      isDeployStatusLoading: false,
      isModelReportCommentLoading1: false,
      isModelReportCommentLoading2: false,
      isAddDatasetBlocked: false,
      versions: [],
      selectedVersion: null,
      project: {},
      datasets: [],
      files: [{ uploaded: false, name: null, size: null }],
      noDataset: false,
      datasetId: '',
      sortDesc: true,
      sortBy: 'Version',
      headers:[
        { text: 'バージョン', value: 'Version'},
        { text: '学習', value: 'train' , sortable: false },
        { text: '精度', value: 'Score' },
        { text: '予測', value: 'predict', sortable: false },
        { text: '', value: 'operation' , sortable: false },
      ],
      isDragging: false,
      file: null,
      testData: null,
      detail: "",
      jsonDescribeData: [],
      headers2: [
        { text: '予測対象', value: 'target', sortable: false},
        { text: 'カラム名', value: 'name'},
        { text: '型', value: 'dtype' },
        { text: '要素数', value: 'count'},
        { text: '平均値', value: 'mean'},
        { text: '標準偏差', value: 'std'},
        { text: '最小値', value: 'min'},
        { text: '中央値', value: '50%'},
        { text: '最大値', value: 'max'},
        { text: '一意な要素数', value: 'unique' },
        { text: '最頻値', value: 'top' },
        { text: '最頻値の出現回数', value: 'freq' },
      ],
      selectedItems: [],
      trainCompletedIndex: -1,
      trainStatus: "",
      modelScore: "",
      modelReportNoCommentsText1: "",
      modelReportNoCommentsText2: "",
      modelReportCommentsStatus: "",
      modelReportHeaders: [],
      modelReportitems: [],
      modelReportitemsMaxValue: 0,
      modelReportitemsPerPage: -1,
      residualsCommentEvaluation: 0,
      residualsCommentModelFeature: "",
      residualsCommentImprovementPoints: "",
      predictedTrueCommentEvaluation: 0,
      predictedTrueCommentModelFeature: "",
      predictedTrueCommentImprovementPoints: "",
      confusionMatrixCommentEvaluation: 0,
      confusionMatrixCommentModelFeature: "",
      confusionMatrixCommentImprovementPoints: "",
      accuracyTableCommentEvaluation: 0,
      accuracyTableCommentModelFeature: "",
      accuracyTableCommentImprovementPoints: "",
      featureImportanceHeaders: [
        { text: '順位', value: 'order'},
        { text: '特徴量', value: 'key' },
        { text: '重要度', value: 'normalizedValue' },
      ],
      featureImportanceItems: [],
      selectedTab: 0, 
      tabs: ['一つずつ予測', 'まとめて予測'],
      endpointStatus: "",
      endpointDeletionStatus: "",
      deployStatus: "",
      predictResult: "--",
      isPredictResultCopied: false,
      failedPredictionMessage: false,
      successPredictionMessage: false,
      isHovered: false,
      hoveredIconIndex: -1,
      features: {},
      copiedFeatures: {},
      isPredictFormValid: false,
      csvData: null,
      predictions: null,
    };
  },
  async created() {
    this.loading = true;
    this.getProjectAndDatasets();
    const storedData = sessionStorage.getItem('data')
    if (storedData) {
      const data = JSON.parse(storedData);
      this.datasetId = data.datasetId;
      this.selectedVersion = data.selectedVersion;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('beforeunload', () => {
      const data = {
        datasetId: this.datasetId,
        selectedVersion: this.selectedVersion,
      };
      sessionStorage.setItem('data', JSON.stringify(data));
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.pageLoading) {
      const answer = window.confirm("予期せぬエラーが発生する可能性があります。このページを離れますか？")
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next();
    }
  },
  computed: {
    describeData() {
      return Object.keys(this.jsonDescribeData).map(key => {
        return {
          name: key,
          count: this.jsonDescribeData[key].count,
          unique: this.jsonDescribeData[key].unique,
          top: this.jsonDescribeData[key].top,
          freq: this.jsonDescribeData[key].freq,
          mean: this.jsonDescribeData[key].mean,
          std: this.jsonDescribeData[key].std,
          min: this.jsonDescribeData[key].min,
          "25%": this.jsonDescribeData[key]["25%"],
          "50%": this.jsonDescribeData[key]["50%"],
          "75%": this.jsonDescribeData[key]["75%"],
          max: this.jsonDescribeData[key].max,
          dtype: this.jsonDescribeData[key].dtype
        };
      });
    },
    isForecastHorizonValid() {
      if(this.project.Type === "Forecast" && !Object.keys(this.jsonDescribeData).includes(this.timeColumnName)){
        return false;
      }
      else if(this.project.Type === "Forecast"){
        return this.validateForecastHorizon(this.forecastHorizon);
      }
      else{
        return true;
      }
    },
    isTrainDisabled() {
      if (this.datasets.length === 0) {
        return false;
      }
      else if (this.trainCompletedIndex === -1 && this.trainStatus !== "NotStarted") {
        return true;
      }
      else if (this.trainCompletedIndex !== -1) {
        return true;
      }
      else {
        return false;
      }
    },
    isPredictDisabled() {
      if (this.endpointStatus !== "Succeeded" || this.deployStatus !== "Succeeded" || this.endpointDeletionStatus === "Deleting") {
        return true;
      } else {
        return false;
      }
    },
    predictResultCopyBtnText() {
      return this.isPredictResultCopied ? 'コピーしました！' : '結果をコピーする';
    },
    timeTooltipText() {
      return '時間カラム名とは、データの中で時間に関する情報が書かれている特別なカラムのことです。時間カラム名を指定することで、データの中での時間的な順序を正しく理解し、より正確な時系列予測を行うことが可能になります。';
    },
    forecastTooltipText() {
      return '予測期間（Forecast Horizon）とは、時系列予測において未来の予測をどれくらい先まで行うかを指定するものです。時間カラム名と対応しています。例えば、時間カラム名が1時間単位である場合、「24」と入力すると24時間先までの予測が可能になります。';
    },
  },
  methods: {
    async getProjectAndDatasets() {
      try {
        const projectResponse = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        this.project = projectResponse.data.results.projects;

        const datasetResponse = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        if (datasetResponse.data.results.datasets.length === 0) {
          this.noDataset = true;
        } else {
          this.noDataset = false;
          this.datasets = datasetResponse.data.results.datasets;
          this.calculateTableHeight(1);
          this.calculateTableHeight(2);
          this.$store.state.user_model["dataset"] = datasetResponse.data.results.datasets;
          this.updateDatasetsVersions();
          if (this.selectedVersion) {
            this.datasetId = this.datasets[this.selectedVersion - 1].DatasetId;
          } else {
            this.selectedVersion = this.versions[0];
            this.datasetId = this.datasets[this.datasets.length - 1].DatasetId;
          }
        }

        this.pageLoading = false;
        this.loading = false;
        if (!this.noDataset) {
          await this.getDatasetInfo(this.datasetId);
        }
        if (process.env.NODE_ENV === 'development') {
          console.log(this.datasets);
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 401) {
          this.$router.push({ name: 'notfound' });
        }
      }
    },

    updateDatasetsVersions(){
      this.datasets.sort((a, b) => a.Version - b.Version);
      this.datasets.forEach((dataset, index) => {
        dataset.DisplayVersion = index + 1;
      });
      const displayVersions = this.datasets.map(dataset => dataset.DisplayVersion);
      this.versions = displayVersions.sort((a, b) => b - a);
    },
     handleScroll() {
      this.scrollPosition = window.scrollY;
      let newHighlightedMenuIndex = -1;
      this.highlightMenu.forEach((menu, index) => {
        const targetElement = document.getElementById(menu.id);
        let targetPosition = null;
        if (targetElement){
          targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 240;
        }
        if (this.scrollPosition >= targetPosition) {
          newHighlightedMenuIndex = index;
        }
        menu.isHighlighted = this.highlightedMenuIndex === index;
      });

      if (this.highlightedMenuIndex !== newHighlightedMenuIndex) {
        this.selectedMenu = '';
        this.highlightedMenuIndex = newHighlightedMenuIndex;
      }
    },
    scrollToTarget(targetId) {
      const element = document.getElementById(targetId);
      const duration = 1000; // 1 second in milliseconds
      const start = window.pageYOffset;
      const end = element.getBoundingClientRect().top + window.pageYOffset;

      const startTime = performance.now();

      const scrollAnimation = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const scrollY = this.easeInOutQuad(elapsedTime, start, end - start, duration);

        window.scrollTo(0, scrollY);

        if (elapsedTime < duration) {
          requestAnimationFrame(scrollAnimation);
        }
      };

      requestAnimationFrame(scrollAnimation);
    },

    // Easing function for smooth scroll
    easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    },
    calculateTableHeight(header) {
      let rowCount = 0;
      let tableHeight = 500;
      if (header === 1) {
        rowCount = Object.keys(this.datasets).length;
        if(rowCount < 10){
          tableHeight = rowCount * 48 + 48;
        }
      }
      else if(header === 2){
        rowCount = Object.keys(this.jsonDescribeData).length;
        if (rowCount < 10) {
          tableHeight = rowCount * 48 + 52;
        }
      }
      return tableHeight;
    },
    formatNumber(value) {
      if (Math.abs(value) == 0) {
        return value.toFixed(2);
      }
      if (Math.abs(value) < 0.01) {
        const formatted = value.toFixed(11);
        const trimmed = formatted.replace(/\.?0+$/, "");
        return trimmed;
      } else {
        return value.toFixed(2);
      }
    },

    resetPredictionData() {
      this.predictResult = "--";
      this.testData = null;
      this.predictions = null;
      this.failedPredictionMessage = false;
      this.successPredictionMessage = false;
    },

    async getDatasetInfo(datasetId) {
      if (this.hasDatasetPageBeenDisplayed === false) {
        this.describeLoading = true;
        this.predictSheetLoading = true;
      }
      this.datasetId = datasetId;
      if (!this.selectedVersion) {
        this.selectedVersion = this.versions[0];
      }
      try {
        const response = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        if (process.env.NODE_ENV === 'development') {
          console.log(response.data.results.datasets[0]);
        }
        const dataset = response.data.results.datasets[0];
        if(dataset){
          this.trainStatus = dataset.TrainStatus;
          this.datasetId = dataset.DatasetId;
          this.endpointStatus = dataset.EndpointStatus;
          this.deployStatus = dataset.DeployStatus;
          this.selectedItems[0] = dataset.Target;
          if(this.project.Type === "Forecast"){
            this.timeColumnName = dataset.TimeColumnName;
            this.forecastHorizon = dataset.ForecastHorizon;
          }
          this.trainCompletedIndex = this.datasets.findIndex(item => item.TrainStatus === "Completed");
          if (["Starting", "Creating", "Running"].includes(this.trainStatus)) {
            this.isTrainStatusLoading = true;
            await this.getTrainStatus();
            this.checkTrainStatus();
          }
          if (["Creating","Succeeded"].includes(this.endpointStatus)) {
            this.isDeployStatusLoading = true;
            await this.getEndpointStatus();
            await this.getDeployStatus();
            if(this.endpointStatus === "Succeeded" && this.deployStatus === "NotStarted"){
              this.startDeployment();
            }else{
              this.checkDeployStatus();
            }
          }
          if(["Updating", "Deleting"].includes(this.deployStatus)){
            this.isDeployStatusLoading = true;
            await this.getEndpointStatus();
            await this.getDeployStatus();
            this.checkDeployStatus();
          }
          if (this.trainCompletedIndex !== -1) {
            this.selectedItems[0] = this.datasets[this.trainCompletedIndex].Target;
            if (this.project.Type === "Forecast") {
              this.timeColumnName = this.datasets[this.trainCompletedIndex].TimeColumnName;
              this.forecastHorizon = this.datasets[this.selectedVersion - 1].ForecastHorizon;
              if(!this.forecastHorizon){
                this.forecastHorizon = this.datasets[this.trainCompletedIndex].ForecastHorizon;
              }
            }
          }
          else{
            this.trainCompletedIndex = this.datasets.findIndex(item => item.TrainStatus === "Running");
            if (this.trainCompletedIndex !== -1) {
              this.selectedItems[0] = this.datasets[this.trainCompletedIndex].Target;
              if (this.project.Type === "Forecast") {
                this.timeColumnName = this.datasets[this.trainCompletedIndex].TimeColumnName;
                this.forecastHorizon = this.datasets[this.selectedVersion - 1].ForecastHorizon;
                if (!this.forecastHorizon) {
                  this.forecastHorizon = this.datasets[this.trainCompletedIndex].ForecastHorizon;
                }
              }
            }
          }
          if (this.hasDatasetPageBeenDisplayed === false) {
            await this.describeDataset();
            this.isShowDescribeTable = true;
            if (this.trainStatus === "Completed") {
              this.modelScore = 'Score' in dataset ? dataset.Score : "";
              this.loading = false;
              this.getModelReport(this.datasetId, true, true);
              this.getFeatures();
            }
            this.hasDatasetPageBeenDisplayed = true;
          }
        }
        if (process.env.NODE_ENV === 'development') {
          console.log("TrainStatus : " + this.trainStatus);
        }
        this.resetLoadingFlags();
      } catch (err) {
        console.log(err);
        this.resetLoadingFlags();
      }
    },
    resetLoadingFlags() {
      this.pageLoading = false;
      this.loading = false;
      if(this.trainStatus === "Completed"){
        this.isTrainStatusLoading = false;
      }
      if(this.deployStatus === "Succeeded"){
        this.isDeployStatusLoading = false;
      }
      this.isShowDescribeTable = false;
    },
    deleteDataset(datasetId, datasetVersion) {
      if (process.env.NODE_ENV === 'development') {
        console.log(datasetId)
      }
      this.deleteDatasetId = datasetId;
      this.deleteDatasetVersion = datasetVersion.toString();
      this.deletionTitle = "バージョン";
      this.dialogDeleteDataset = true;
    },
    async onVersionSelected() {
      this.datasetId = this.datasets[this.selectedVersion - 1].DatasetId;
      this.resetDataset();
      this.loading = true;
      await this.getDatasetInfo(this.datasetId);
    },
    resetDataset(){
      this.trainStatus = "";
      this.deployStatus = "";
      this.hasDatasetPageBeenDisplayed = false;
      this.failedDatasetDescribe = false;
      this.modelReportNoCommentsText1 = "";
      this.modelReportNoCommentsText2 = "";
      this.confusionMatrixCommentModelFeature = "";
      this.confusionMatrixCommentImprovementPoints = "";
      this.accuracyTableCommentModelFeature = "";
      this.accuracyTableCommentImprovementPoints = "";
      this.predictedTrueCommentModelFeature = "";
      this.predictedTrueCommentImprovementPoints = "";
      this.residualsCommentModelFeature = "";
      this.residualsCommentImprovementPoints = "";
      this.predictResult = "--";
    },
    async describeDataset() {
      this.jsonDescribeData = [];
      try {
        const response = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/describe`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        this.jsonDescribeData = response.data.results;
        const firstPropertyName = Object.keys(this.jsonDescribeData)[0]; 
        this.maxForecastHorizon = this.jsonDescribeData[firstPropertyName].count; 
        this.describeLoading = false;
        if (process.env.NODE_ENV === 'development') {
          console.log(this.jsonDescribeData);
        }
      } catch (err) {
        console.log(err);
        this.describeLoading = false;
        this.failedDatasetDescribe = true;
      }
    },
    validateTarget(item) {
      let targetType = "";
      let showDialog = false;

      if (this.project.Type === "Classification" && item.includes("float")) {
        targetType = "float型";
        showDialog = true;
        this.selectedItems = [];
      } else if (this.project.Type === "Regression" && (item === "object" || item === "string" || item === "bool")) {
        targetType = "カテゴリ型";
        showDialog = true;
        this.selectedItems = [];
      } else if (this.project.Type === "Forecast" && (item === "object" || item === "string" || item === "bool")) {
        targetType = "数値以外";
        showDialog = true;
        this.selectedItems = [];
      }

      this.validateTargetType = targetType;
      this.dialogValidateTarget = showDialog;
      this.dialogValidateTargetFlg = showDialog;
    },

    validateClassificationTarget(numOfUnique){
      let showDialog = false;

      if(this.project.Type === "Classification" && numOfUnique === 1 ){
        showDialog = true;
        this.selectedItems = [];
      }
      this.validateClassificationTargetAlert = showDialog;
      this.dialogValidateTargetFlg = showDialog;
    },

    validateTimeColumnName(value) {
      if (this.project.Type === "Forecast" && !Object.keys(this.jsonDescribeData).includes(value)) {
        return `${value}はカラム名に存在しません。`;
      }
      return true;
    },

    validateForecastHorizon(value) {
      return Number.isInteger(parseFloat(value)) && value >= 1 && value <= this.maxForecastHorizon;
    },

    async trainDataset() {

      this.modelScore = "";
      this.pageLoading = true;
      this.dialogProhibitLeavingPage = true;

      try {
        const computingResponse = await axios.post(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/computing`, null, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        if (process.env.NODE_ENV === 'development') {
          console.log(computingResponse);
        }
        if (computingResponse.data.results === "Succeeded") {
          let postData = {
            "Target": this.selectedItems[0]
          };
          if (this.project.Type === "Forecast") {
            postData.TimeColumnName = this.timeColumnName;
            postData.ForecastHorizon = this.forecastHorizon;
          }

          const trainResponse = await axios.post(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/train`, postData, {
            headers: {
              "X-Api-Key": process.env.VUE_APP_KEY,
              "SessionId": this.$store.state.auth.login.sessionId,
              "Content-Type": "application/json"
            }
          });

          if (process.env.NODE_ENV === 'development') {
            console.log(trainResponse);
          }

          if (trainResponse.data.results.TrainStatus !== "NotStarted") {
            await this.getDatasetInfo(this.datasetId);
            this.scrollToTarget('model');
            this.getProjectAndDatasets();
            this.isTrainStatusLoading = true;
            this.checkTrainStatus();
            this.pageLoading = false;
            this.dialogProhibitLeavingPage = false;
            this.trainStatus = trainResponse.data.results.TrainStatus;
          } else {
            setTimeout(() => {
              this.trainDataset();
            }, 30000);
          }
        } else {
          setTimeout(() => {
            this.trainDataset();
          }, 30000);
        }
      } catch (err) {
        console.log(err);
        this.pageLoading = false;
      }
    },

    checkTrainStatus() {
      setTimeout(async () => {
        if (["Starting", "Creating", "Running"].includes(this.trainStatus)) {
          this.isTrainStatusLoading = true;
          await this.getTrainStatus();
          if (["Starting", "Creating", "Running"].includes(this.trainStatus)) {
            this.checkTrainStatus();
          }
          else{
            this.hasDatasetPageBeenDisplayed = false;
            await this.getDatasetInfo(this.datasetId);
            this.getProjectAndDatasets();
          }
        }

      }, 60000);
    },
    interpolateColor(start, end, ratio) {
      const r = Math.ceil(start[0] * (1 - ratio) + end[0] * ratio);
      const g = Math.ceil(start[1] * (1 - ratio) + end[1] * ratio);
      const b = Math.ceil(start[2] * (1 - ratio) + end[2] * ratio);
      return `rgba(${r}, ${g}, ${b}, 1)`;
    },
    getColor(value, maxValue) {
      const ratio = value / maxValue;
      const startColor = [255, 255, 255];
      const endColor = [25, 118, 210];
      return this.interpolateColor(startColor, endColor, ratio);
    },
    async getModelReport(datasetId,chart1,chart2) {

      let commentRegenerationParams = null;
      let method = "GET";

      if (chart1 === true && chart2 === true) {
        this.isModelReportCommentLoading1 = true;
        this.isModelReportCommentLoading2 = true;
      }
      else if (chart1 === true) {
        this.isModelReportCommentLoading1 = true;
        method = "PUT";
      } 
      else if (chart2 === true) {
        this.isModelReportCommentLoading2 = true;
        method = "PUT";
      }
      let url = `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${datasetId}/model/report`;
      if(method === "PUT"){
        if (this.project.Type === "Classification") {
          commentRegenerationParams = `confusion_matrix=${chart1}&accuracy_table=${chart2}`;
        } else if (this.project.Type === "Regression" || this.project.Type === "Forecast") {
          commentRegenerationParams = `predicted_true=${chart1}&residuals=${chart2}`;
        }
        url += `?${commentRegenerationParams}`;
      }
      await axios({
        method: method,
        url: url,
        headers: { "X-Api-Key": process.env.VUE_APP_KEY, "SessionId": this.$store.state.auth.login.sessionId, "Content-Type": "application/json" },
      },).then((response) => {
        if (process.env.NODE_ENV === 'development') {
          console.log(response);
        }
        const featureImportance = response.data.results.feature_importance;

        // 特徴重要度の値を取得して最大値と最小値を計算
        const values = Object.values(featureImportance);
        const maxValue = Math.max(...values);
        const minValue = Math.min(...values);

        // 各特徴の情報を処理して新しい配列に格納
        this.featureImportanceItems = Object.keys(featureImportance).map((key, index) => ({
          key, 
          value: featureImportance[key],  // 特徴の重要度値
          normalizedValue: (featureImportance[key] - minValue) / (maxValue - minValue),  // 正規化された値
          order: index + 1,  // 順序（1から始まる）
        }));

        if (process.env.NODE_ENV === 'development') {
          console.log(this.featureImportanceItems);
        }

        if(chart1 === true && chart2 === true){
          if (this.project.Type == "Classification") {
            const confusionMatrix = response.data.results.confusion_matrix.data.matrix;
            const probabilityTables = response.data.results.accuracy_table.data.probability_tables;
            const confusionMatrixLabels = response.data.results.confusion_matrix.data.class_labels;
            this.modelReportitems = confusionMatrix.map((subArray, index) => {
              const item = { label: confusionMatrixLabels[index] };
              for (let i = 0; i < subArray.length; i++) {
                item[confusionMatrixLabels[i]] = subArray[i];
              }
              return item;
            });
            this.modelReportHeaders = confusionMatrixLabels.map(item => ({
              text: item,
              value: item,
              sortable: false
            }));
            this.modelReportHeaders.unshift({ text: '', value: 'label', sortable: false });
            let maxValue = 0;
            for (const obj of this.modelReportitems) {
              for (const key in obj) {
                if (
                  Object.prototype.hasOwnProperty.call(obj, key) &&
                  typeof obj[key] === 'number'
                ) {
                  maxValue = Math.max(maxValue, obj[key]);
                }
              }
            }
            this.modelReportitemsMaxValue = maxValue;

            const datasets = [];
            const colors = ['#1976d2', '#d31f22', '#159f13', '#cf7019', '#8d45db', 'brown', '#de589e', 'olive', 'cyan'];

            for (let i = 0; i < probabilityTables.length; i++) {
              const thresholdTable = probabilityTables[i];
              const tp = thresholdTable.map(row => row[0]);
              const fp = thresholdTable.map(row => row[1]);
              const tn = thresholdTable.map(row => row[2]);
              const fn = thresholdTable.map(row => row[3]);
              const tpr = tp.map((value, index) => value / (value + fn[index]));
              const fpr = fp.map((value, index) => value / (value + tn[index]));

              let color;
              if (i < colors.length) {
                color = colors[i];
              } else {
                color = '#' + Math.floor(Math.random() * 16777215).toString(16);
                colors.push(color);
              }

              const dataset = {
                label: confusionMatrixLabels[i],
                data: tpr.map((value, index) => ({ x: fpr[index], y: value })),
                borderColor: color,
                backgroundColor: color,
                pointRadius: 0,
                pointHoverRadius: 0,
                showLine: true,
                fill: false,
              };
              datasets.push(dataset);
            }
            const data = {
              datasets: datasets,
            };
            const options = {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  onClick: false
                },
              },
              scales: {
                x: {
                  grid: {
                    display: true,
                  },
                  title: {
                    display: true,
                    text: '偽陽性率',
                  },
                },
                y: {
                  grid: {
                    display: true,
                  },
                  title: {
                    display: true,
                    text: '真陽性率',
                  },
                },
              },
            };

            const ctx = this.$refs.chartCanvas2.getContext('2d');
            new Chart(ctx, {
              type: 'scatter',
              data: data,
              options: options,
            });
          }
          if (this.project.Type == "Regression" || this.project.Type == "Forecast") {
            const binCounts = response.data.results.predicted_true.data.bin_counts;
            const binAverages = response.data.results.predicted_true.data.bin_averages;
            const binErrors = response.data.results.predicted_true.data.bin_errors;
            const binEdges = response.data.results.predicted_true.data.bin_edges;
            const newBinEdges = [];
            for (let i = 0; i < binEdges.length - 1; i++) {
              const midpoint = (binEdges[i] + binEdges[i + 1]) / 2;
              newBinEdges.push(midpoint);
            }
            const filteredBinAverages = [];
            const filteredBinPlusError = [];
            const filteredBinMinusError = [];
            const filteredBinEdges = [];
            for (let i = 0; i < binCounts.length; i++) {
              if (binCounts[i] !== 0) {
                filteredBinAverages.push(binAverages[i]);
                filteredBinPlusError.push(binAverages[i] + binErrors[i]);
                filteredBinMinusError.push(binAverages[i] - binErrors[i]);
                filteredBinEdges.push(newBinEdges[i]);
              }
            }
            const maxBinPlusError = Math.max(...filteredBinPlusError);
            const chartData = {
              datasets: [
                {
                  label: 'bin_average',
                  data: filteredBinEdges.map((value, index) => ({ x: value, y: filteredBinAverages[index] })),
                  backgroundColor: 'transparent',
                  borderColor: '#1976d2',
                  borderWidth: 3,
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  showLine: true,
                  fill: '-1',
                },
                {
                  label: '予測範囲',
                  data: filteredBinEdges.map((value, index) => ({ x: value, y: filteredBinPlusError[index] })),
                  backgroundColor: '#bfc0f2',
                  borderColor: 'transparent',
                  borderWidth: 1,
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  showLine: true,
                  fill: '1',
                  order: 2,
                },
                {
                  label: '予測範囲',
                  data: filteredBinEdges.map((value, index) => ({ x: value, y: filteredBinMinusError[index] })),
                  backgroundColor: '#bfc0f2',
                  borderColor: 'transparent',
                  borderWidth: 1,
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  showLine: true,
                  fill: '1',
                  order: 2,
                },
                {
                  label: '理想線',
                  data: [{ x: filteredBinEdges[0], y: filteredBinEdges[0] }, { x: maxBinPlusError, y: maxBinPlusError }],
                  backgroundColor: 'transparent',
                  borderColor: '#18cd6f',
                  borderWidth: 3,
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  showLine: true,
                  borderDash: [5, 5],
                },
              ],
            };
            const ctx1 = this.$refs.chartCanvas1.getContext('2d');
            new Chart(ctx1, {
              type: 'scatter',
              data: chartData,
              options: {
                aspectRatio: 1,
                plugins: {
                  legend: {
                    labels: {
                      filter: function (items) {
                        return items.datasetIndex != 2;
                      }
                    },
                    onClick: false
                  },
                },
                scales: {
                  x: {
                    type: 'linear',
                    position: 'bottom',
                    grid: {
                      display: true,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: '真値',
                    },
                    ticks: {
                      maxTicksLimit: 8,
                    },
                  },
                  y: {
                    type: 'linear',
                    position: 'left',
                    grid: {
                      display: true,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: '予測値',
                    },
                    ticks: {
                      maxTicksLimit: 8,
                    },
                  },
                },
              },
            });

            const residualsBinEdges = response.data.results.residuals.data.bin_edges;
            const residualsBinCounts = response.data.results.residuals.data.bin_counts;
            const ctx2 = document.getElementById('chartCanvas2');
            new Chart(ctx2.getContext('2d'), {
              type: 'bar',
              data: {
                labels: residualsBinEdges.map((edge, index) => {
                  if (index === 0) {
                    return `${edge.toFixed(2)}〜`;
                  } else if (index === residualsBinEdges.length - 1) {
                    return `${residualsBinEdges[index - 1].toFixed(2)}〜${edge.toFixed(2)}`;
                  } else {
                    return `${residualsBinEdges[index - 1].toFixed(2)}〜${edge.toFixed(2)}`;
                  }
                }),
                datasets: [
                  {
                    label: 'Bin Count',
                    data: residualsBinCounts,
                    backgroundColor: '#1976d2',
                    borderColor: 'transparent'
                  }
                ]
              },
              options: {
                maintainAspectRatio: true,
                aspectRatio: 1,
                scales: {
                  x:{
                    scaleLabel: {
                      display: true,
                      labelString: 'Residuals'
                    },
                    ticks: {
                      maxRotation: 45,
                      minRotation: 45,
                    }
                  },
                  y:{
                    scaleLabel: {
                      display: true,
                      labelString: 'Bin Count'
                    },
                    ticks: {
                      beginAtZero: true,
                      precision: 0
                    }
                  }
                },
                plugins: {
                  legend: {
                    position: 'top',
                    labels: {
                      fontColor: '#384048'
                    },
                    onClick: false
                  },
                },
              },
            });
          }
          if (this.project.Type == "Forecast") {
            const time = response.data.results.forecast_table.time;
            const y_true = response.data.results.forecast_table.y_true;
            const y_pred = response.data.results.forecast_table.y_pred;
            const PI_upper_bound = response.data.results.forecast_table.PI_upper_bound;
            const PI_lower_bound = response.data.results.forecast_table.PI_lower_bound;
            const ctx = document.getElementById('chartCanvas3');
            new Chart(ctx.getContext('2d'), {
              type: 'line',
              data: {
                labels: time,
                datasets: [
                  {
                    label: '実際',
                    borderColor: '#1976d2',
                    borderWidth: 3,
                    backgroundColor: 'transparent',
                    data: y_true,
                    pointRadius: 0,
                  },
                  {
                    label: '予測',
                    borderColor: 'red',
                    borderWidth: 3,
                    backgroundColor: 'transparent',
                    data: y_pred,
                    pointRadius: 0,
                  },
                  {
                    label: '予測範囲',
                    borderColor: 'transparent',
                    backgroundColor: '#f8dada',
                    data: PI_upper_bound,
                    pointRadius: 0,
                    fill: '-1',
                  },
                  {
                    label: '予測範囲',
                    borderColor: 'transparent',
                    backgroundColor: '#f8dada',
                    data: PI_lower_bound,
                    pointRadius: 0,
                    fill: '1',
                  },
                ],
              },
              options: {
                plugins: {
                  legend: {
                    position: 'top',
                    labels: {
                      filter: function (items) {
                        return items.datasetIndex != 2;
                      },
                      fontColor: '#384048'
                    },
                    onClick: false
                  },
                },
                scales: {
                  x:{
                    scaleLabel: {
                      display: true,
                      labelString: '時間',
                    },
                    ticks: {
                      maxTicksLimit: this.$vuetify.breakpoint.width < 600 ? 10 : 30, // スマホサイズの場合は10、それ以外は30に設定
                    },
                  },
                  y:{
                    scaleLabel: {
                      display: true,
                      labelString: '目標値',
                    },
                  },
                },
              },
            });
          }
        }
        if (response.data.results.comment) {
          this.modelReportCommentsStatus = response.data.results.status;
          const comment = response.data.results.comment;
          const isClassification = this.project.Type === "Classification";
          const isRegression = this.project.Type === "Regression";
          const isForecast = this.project.Type === "Forecast";
          if (isClassification) {
            this.confusionMatrixCommentEvaluation = comment.confusion_matrix_comment.evaluation;
            this.confusionMatrixCommentModelFeature = comment.confusion_matrix_comment.model_feature;
            this.confusionMatrixCommentImprovementPoints = comment.confusion_matrix_comment.improvement_points;
            this.accuracyTableCommentEvaluation = comment.accuracy_table_comment.evaluation;
            this.accuracyTableCommentModelFeature = comment.accuracy_table_comment.model_feature;
            this.accuracyTableCommentImprovementPoints = comment.accuracy_table_comment.improvement_points;
          }
          if (isRegression || isForecast) {
            this.predictedTrueCommentEvaluation = comment.predicted_true_comment.evaluation;
            this.predictedTrueCommentModelFeature = comment.predicted_true_comment.model_feature;
            this.predictedTrueCommentImprovementPoints = comment.predicted_true_comment.improvement_points;
          }
          if (isRegression || isForecast) {
            this.residualsCommentEvaluation = comment.residuals_comment.evaluation;
            this.residualsCommentModelFeature = comment.residuals_comment.model_feature;
            this.residualsCommentImprovementPoints = comment.residuals_comment.improvement_points;
          }
          this.modelReportNoCommentsText1 = "";
          this.modelReportNoCommentsText2 = "";
          this.isModelReportCommentLoading1 = false;
          this.isModelReportCommentLoading2 = false;
        }
        if(response.data.results.status === "RUNNING"){
          this.checkIfCommentExists(chart1, chart2);
        }
        if (response.data.results.status === "FAILED") {
          this.setChartFailureMessages(chart1, chart2);
          this.isModelReportCommentLoading1 = false;
          this.isModelReportCommentLoading2 = false;
        }
      }).catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 502) {
          this.setChartFailureMessages(chart1, chart2);
        }
        this.isModelReportCommentLoading1 = false;
        this.isModelReportCommentLoading2 = false;
      });
    },
    setChartFailureMessages(chart1, chart2) {
      const failureMessage = "コメント取得に失敗しました。時間をおいて、「コメント再生成」ボタンを押してください。";
      if (chart1 === true) {
        this.modelReportNoCommentsText1 = failureMessage;
        this.confusionMatrixCommentModelFeature = "";
        this.confusionMatrixCommentImprovementPoints = "";
        this.predictedTrueCommentModelFeature = "";
        this.predictedTrueCommentImprovementPoints = "";
      }
      if (chart2 === true) {
        this.modelReportNoCommentsText2 = failureMessage;
        this.accuracyTableCommentModelFeature = "";
        this.accuracyTableCommentImprovementPoints = "";
        this.residualsCommentModelFeature = "";
        this.residualsCommentImprovementPoints = "";
      }
      if (chart1 === true && chart2 === true) {
        this.modelReportNoCommentsText1 = failureMessage;
        this.modelReportNoCommentsText2 = failureMessage;
      }
    },
     async checkIfCommentExists(chart1, chart2) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/model/report`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });

        if (process.env.NODE_ENV === 'development') {
          console.log(response);
        }

        this.modelReportCommentsStatus = response.data.results.status;

        if (response.data.results.comment) {
          const comment = response.data.results.comment;
          const isClassification = this.project.Type === "Classification";
          const isRegression = this.project.Type === "Regression";
          const isForecast = this.project.Type === "Forecast";

          if (isClassification) {
            this.confusionMatrixCommentEvaluation = comment.confusion_matrix_comment.evaluation;
            this.confusionMatrixCommentModelFeature = comment.confusion_matrix_comment.model_feature;
            this.confusionMatrixCommentImprovementPoints = comment.confusion_matrix_comment.improvement_points;
            this.accuracyTableCommentEvaluation = comment.accuracy_table_comment.evaluation;
            this.accuracyTableCommentModelFeature = comment.accuracy_table_comment.model_feature;
            this.accuracyTableCommentImprovementPoints = comment.accuracy_table_comment.improvement_points;
          }

          if (isRegression || isForecast) {
            this.predictedTrueCommentEvaluation = comment.predicted_true_comment.evaluation;
            this.predictedTrueCommentModelFeature = comment.predicted_true_comment.model_feature;
            this.predictedTrueCommentImprovementPoints = comment.predicted_true_comment.improvement_points;
          }

          if (isRegression || isForecast) {
            this.residualsCommentEvaluation = comment.residuals_comment.evaluation;
            this.residualsCommentModelFeature = comment.residuals_comment.model_feature;
            this.residualsCommentImprovementPoints = comment.residuals_comment.improvement_points;
          }

          this.modelReportNoCommentsText1 = "";
          this.modelReportNoCommentsText2 = "";
          this.isModelReportCommentLoading1 = false;
          this.isModelReportCommentLoading2 = false;
        } else if (response.data.results.status === "RUNNING") {
          setTimeout(() => {
            this.checkIfCommentExists(chart1, chart2);
          }, 30000);
        } else if (response.data.results.status === "FAILED") {
          this.setChartFailureMessages(chart1, chart2);
          this.isModelReportCommentLoading1 = false;
          this.isModelReportCommentLoading2 = false;
        }
      } catch (err) {
        console.log(err);
        this.isModelReportCommentLoading1 = false;
        this.isModelReportCommentLoading2 = false;
      }
    },

    async createEndpoint(){
      const endpointResponse = await axios.post(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/endpoint`, null, {
        headers: {
          "X-Api-Key": process.env.VUE_APP_KEY,
          "SessionId": this.$store.state.auth.login.sessionId,
          "Content-Type": "application/json"
        }
      });
      this.endpointStatus = endpointResponse.data.results;
      if (process.env.NODE_ENV === 'development') {
        console.log("EndpointStatus : " + this.endpointStatus);
      }
    },

    async startDeployment(){
      let postData = {
        "Target": this.selectedItems[0]
      };
      const deployResponse = await axios.post(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/deploy`, postData, {
        headers: {
          "X-Api-Key": process.env.VUE_APP_KEY,
          "SessionId": this.$store.state.auth.login.sessionId,
          "Content-Type": "application/json"
        }
      });
      this.deployStatus = deployResponse.data.results;
      if (process.env.NODE_ENV === 'development') {
        console.log("DeployStatus : " + this.deployStatus);
      }
    },

    async deployDataset() {
      if (this.endpointStatus === "NotStarted") {
        this.pageLoading = true;
      }
      this.isDeployStatusLoading = true;
      try {
        await this.getEndpointStatus();
        if (this.endpointStatus === "NotStarted" || this.endpointStatus === "") {
          await this.createEndpoint();
          this.getProjectAndDatasets();
        }
        this.pageLoading = false;
        if (this.endpointStatus === "Succeeded") {
          if (this.deployStatus === "NotStarted") {
            this.startDeployment();
          }

          if (this.deployStatus === "Updating") {
            await this.getDatasetInfo(this.datasetId);
            this.getProjectAndDatasets();
            this.checkDeployStatus();
          }
          else {
            setTimeout(() => {
              this.deployDataset();
            }, 30000);
          }
        } else {
          setTimeout(() => {
            this.deployDataset();
          }, 30000);
        }
      } catch (err) {
        console.log(err);
        this.deployDataset();
      }
    },

    checkDeployStatus() {
      setTimeout(async () => {
        if (this.deployStatus === 'Updating' || this.endpointStatus === "Deleting") {
          this.isDeployStatusLoading = true;
          await this.getDeployStatus();
          if (this.deployStatus === 'Updating' || this.endpointStatus === "Deleting") {
            this.checkDeployStatus();
          }
          else {
            this.getProjectAndDatasets();
          }
        }
      }, 60000);
    },
    
    async getFeatures() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/predict`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        if (this.project.Type === "Forecast") {
          delete response.data.results[this.selectedItems[0]];
          const keys = Object.keys(response.data.results);
          for (let i = 0; i < keys.length; i++) {
            const name = keys[i];
            if (response.data.results[keys[i]]['dtype'] === 'string') {
              this.features[name] = "";
            }
            else if (response.data.results[keys[i]]['dtype'].includes("int")) {
              this.features[name] = response.data.results[keys[i]]['dtype'];
            }
            else if (response.data.results[keys[i]]['dtype'].includes("float")) {
              this.features[name] = response.data.results[keys[i]]['dtype'];
            } else {
              this.features[name] = response.data.results[keys[i]]['dtype'];
            }
            this.copiedFeatures[name] = null;
          }
        } else {
          for (let i = 0; i < response.data.results.length; i++) {
            const name = response.data.results[i]['name'];
            if (response.data.results[i]['type'] === 'string') {
              this.features[name] = "";
            }
            else if (response.data.results[i]['type'].includes("int")) {
              this.features[name] = response.data.results[i]['type'];
            }
            else if (response.data.results[i]['type'].includes("float")) {
              this.features[name] = response.data.results[i]['type'];
            } else {
              this.features[name] = response.data.results[i]['type'];
            }
            this.copiedFeatures[name] = null;
          }
        }
        this.predictSheetLoading = false;
        if (process.env.NODE_ENV === 'development') {
          console.log(this.features);
          console.log(this.copiedFeatures);
        }
      } catch (err) {
        console.log(err);
        this.predictSheetLoading = false;
      }
    },

    onSelected(item) {
      if (this.selectedItems.length > 0 && !this.selectedItems.includes(item)) {
        this.selectedItems = [item];
      } else {
        this.selectedItems = [item];
      }
      if (process.env.NODE_ENV === 'development') {
        console.log(this.selectedItems)
      }
    },
    async getTrainStatus() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/train`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        if (process.env.NODE_ENV === 'development') {
          console.log(response.data.results);
        }
        this.trainStatus = response.data.results.TrainStatus;
        if ('Score' in response.data.results) {
          this.modelScore = response.data.results.Score;
        } else {
          this.modelScore = "";
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getEndpointStatus(){
      try {
        const endpointResponse = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/endpoint`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        this.endpointStatus = endpointResponse.data.results;
        if (process.env.NODE_ENV === 'development') {
          console.log("EndpointStatus : " + this.endpointStatus);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getDeployStatus() {
      try {
        const deployResponse = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/deploy`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        this.deployStatus = deployResponse.data.results;
        if (process.env.NODE_ENV === 'development') {
          console.log("DeployStatus : " + this.deployStatus);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async addDataset() {
      this.pageLoading = true;
      this.isShowDescribeTable = true;

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset`,
          {
            "FileSize": this.file.size,
            "Detail": this.detail,
          },
          {
            headers: {
              "X-Api-Key": process.env.VUE_APP_KEY,
              "SessionId": this.$store.state.auth.login.sessionId,
              "Content-Type": "application/json",
            },
          }
        );

        if (process.env.NODE_ENV === 'development') {
          console.log(response);
        }
        await this.upload(response, this.file);

      } catch (reason) {
        console.log(reason);
        if (reason.response && reason.response.status === 429) {
          this.isAddDatasetBlocked = true;
        }
        this.pageLoading = false;
      }
    },

    async upload(res, file) {
      try {
        const url = res.data.results.presignedUrl;
        const item = res.data.results.datasetItem;

        await axios.put(url, file, {
          headers: {
            "x-ms-blob-type": "BlockBlob",
          }
        });

        Object.entries(item).forEach(([key, value]) => {
          item[key] = value['S'];
        });

        this.addDatasetEmit(item);
        this.detail = "";
        this.file = null;
      } catch (err) {
        console.log(err);
        this.pageLoading = false;
      }
    },

    onDrop(e,isDataset) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const _files = e.dataTransfer.files;
      if(isDataset){
        this.file = _files[0];
      }
      else{
        this.testData = _files[0];
      }
    },
    onDragEnter(e) {
      e.preventDefault();
      this.isDragging = true;
      this.dragCount++;
    },
    onDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    },
    submitForm() {
      if ((this.selectedTab === 0 && this.$refs.predictForm[0].validate()) || this.selectedTab === 1) {
        this.predictTestData();
      }
    },
    getInputType(type) {
      if (type.includes("int") || type.includes("float")) {
        return 'number';
      } else {
        return 'text';
      }
    },
    getStep(type) {
      if (type.includes("int")) {
        return '1';
      }
      else if (type.includes("float")) {
        return '0.1';
      }
      else {
        return null;
      }
    },
    getRules(type) {
      if (type.includes("int")) {
        return [
          v => !!v || '整数を入力してください',
          v => /^[-]?\d+$/.test(v) || '整数を入力してください'
        ]
      }
      else if (type.includes("float")) {
        return [
          v => !!v || '小数を入力してください',
          v => /^[-]?\d*\.\d+$/.test(v) || '小数を入力してください'
        ]
      }
      else {
        return [
          v => !!v || '文字列を入力してください',
          v => typeof v === 'string' || '文字列を入力してください'
        ]
      }
    },
    handleCsvFileUpload(event,uploadAreaNum) {
      this.failedPredictionMessage = false;
      this.successPredictionMessage = false;
      let file = event;
      if (!file) return;

      Papa.parse(file, {
        complete: (result) => {
          if(uploadAreaNum === 1){
            let data = this.convertToJSON(result.data, 1);
            if (process.env.NODE_ENV === 'development') {
              console.log(data);
            }
          }
          else if(uploadAreaNum === 2){
            this.csvData = this.convertToJSON(result.data, 2);
            if (process.env.NODE_ENV === 'development') {
              console.log(this.csvData);
            }
          }
        },
        header: true,
      });
    },
   convertToJSON(data, uploadAreaNum) {
      const jsonData = [];
      const columns = data[0];
      if (uploadAreaNum === 1) {
        if ((this.project.Type === "Classification" || this.project.Type === "Regression") && data.length < 51) { 
          const message = `50行以上のデータが必要です。`;
          this.deleteFile(0);
          this.$refs.uploadArea1.fileFormatIncorrectedAlert(message);
          return;
        }
        if (this.project.Type === "Classification" && Object.keys(columns).length < 2) {
          const message = `多項分類では、2つ以上のカラム名が必要です。`;
          this.deleteFile(0);
          this.$refs.uploadArea1.fileFormatIncorrectedAlert(message);
          return;
        }
        if (!(this.selectedItems[0] in columns) && !this.noDataset && this.trainCompletedIndex !== -1) {
          const message = `予測対象 '${this.selectedItems[0]}' がカラム名に含まれていません。`;
          this.deleteFile(0);
          this.$refs.uploadArea1.fileFormatIncorrectedAlert(message);
          return;
        }
      }
      else if(uploadAreaNum === 2){
        const missingFeatures = []; // 不足している特徴量名を格納する配列

        if ((this.project.Type === "Forecast") && data.length - 1 > this.forecastHorizon) {
          const message = `予測期間は ${this.forecastHorizon} です。${this.forecastHorizon} 以下のテストデータをアップロードしてください。`;
          this.deleteFile(1);
          this.$refs.uploadArea2[0].fileFormatIncorrectedAlert(message);
          return;
        }

        for (let i = 0; i < data.length - 1; i++) {
          const row = data[i];
          const obj = {};
          for (const col in columns) {
            if (col === this.selectedItems[0]) {
              continue; // カラム名がthis.selectedItems[0]と一致する場合、ループをスキップ
            }

            const value = row[col];

            if (!(col in this.copiedFeatures)) {
              const message = `カラム名 '${col}' は学習した特徴量に含まれていません。`;
              this.deleteFile(1);
              this.$refs.uploadArea2[0].fileFormatIncorrectedAlert(message);
              return;
            }

            if (value === "") {
              obj[col] = null; // 空文字列の場合はそのまま代入
            } else {
              obj[col] = !isNaN(value) ? parseFloat(value) : value;
            }
          }
          jsonData.push(obj);
        }

        // this.copiedFeaturesに含まれているが、columnsに存在しない特徴量をチェック
        for (const col in this.copiedFeatures) {
          if (!(col in columns)) {
            missingFeatures.push(col); // 不足している特徴量名を追加
          }
        }

        // 不足している特徴量があればアラートを表示
        if (missingFeatures.length > 0) {
          const message = `特徴量 '${missingFeatures.join("', '")}' が不足しています。`;
          this.deleteFile(1);
          this.$refs.uploadArea2[0].fileFormatIncorrectedAlert(message);
          return;
        }
      }
      return jsonData;
    },

    onFileUploaded(payload) {
      const { index, file } = payload;
      this.files[index] = {
        uploaded: true,
        name: file.name,
        size: file.size,
      };
      if (index === 0) {
        this.file = file;
        this.handleCsvFileUpload(file, 1);
      } else if (index === 1) {
        this.testData = file;
        this.handleCsvFileUpload(file, 2);
      }
    },
    onFileDeleted(index) {
      this.files[index] = { uploaded: false, name: null, size: null };
      if (index === 0) {
        this.file = null;
      } else if (index === 1) {
        this.testData = null;
      }
    },
    deleteFile(index) {
      if (index === 0) {
        this.$refs.uploadArea1.deleteFile();
      } else if (index === 1) {
        this.$refs.uploadArea2[0].deleteFile();
      }
    },
    formatResult(value) {
      if (this.project.Type === 'Classification' || typeof value === 'string') {
        return value; 
      } else if (typeof value === 'number') {
        if (value === 0 || value >= 0.000001) {
          return value.toFixed(6); 
        } else {
          return value.toExponential(6); 
        }
      } else {
        return value; 
      }
    },

    async predictTestData() {
      this.pageLoading = true;

      let data = [];
      if(this.selectedTab === 0){
        data = [this.copiedFeatures];
      }
      else if(this.selectedTab === 1){
        data = this.csvData;
      }
      try {
        const response = await axios.post(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/predict`, {
          data: data,
        }, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        this.pageLoading = false;
        if (this.project.Type === "Forecast") {
          this.predictResult = this.formatResult(response.data.results.forecast[0]);
          this.predictions = response.data.results.forecast;
          if (process.env.NODE_ENV === 'development') {
            console.log(response.data.results.forecast);
          }
        } else {
          this.predictResult = this.formatResult(response.data.results[0]);
          this.predictions = response.data.results;
          if (process.env.NODE_ENV === 'development') {
            console.log(response.data.results);
          }
        }
        if(this.selectedTab === 1){
          await this.addPredictionsToCSV();
          this.downloadCSV();
          this.deleteFile(1);
          this.successPredictionMessage = true;
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 500) {
          this.failedPredictionMessage = true;
          this.testData = null;
          if (this.selectedTab === 1) {
            this.deleteFile(1);
          }
        }
        this.pageLoading = false;
      }
    },
    copyPredictResult() {
      const text = this.predictResult;
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      this.isPredictResultCopied = true;
      setTimeout(() => {
        this.isPredictResultCopied = false;
      }, 3000); // 3秒後にボタンを元に戻す
    },
    addPredictionsToCSV() {
      // 予測結果を元のCSVデータに追加する
      const columns = Object.keys(this.csvData[0]);
      columns.push(this.selectedItems[0]);
      for (let i = 0; i < this.predictions.length; i++) {
        this.csvData[i][this.selectedItems[0]] = this.predictions[i];
      }
    },
    downloadCSV() {
      const csvContent = Papa.unparse(this.csvData, { header: true });
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "predictions.csv");
      link.click();
      this.csvData = null;
      this.testData = null;
      this.predictions = null;
    },
    async deleteEndpoint() {
      this.pageLoading = true;
      try {
        const response = await axios.delete(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/endpoint`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        if (process.env.NODE_ENV === 'development') {
          console.log(response);
        }
        this.endpointDeletionStatus = response.data.results;
        if (process.env.NODE_ENV === 'development') {
          console.log("EndpointDeletionStatus : " + this.endpointDeletionStatus);
        }
        this.resetPredictionData();
        this.getFeatures();
        this.getProjectAndDatasets();
        this.pageLoading = false;
      } catch (err) {
        console.log(err);
        this.pageLoading = false;
      }
    },
    async downloadDataset(datasetId) {
      try {
        this.pageLoading = true;
        const response = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/dataset/${datasetId}`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        if (process.env.NODE_ENV === 'development') {
          console.log(response);
        }
        this.pageLoading = false;
        this.download(response, 'csv');
      } catch (err) {
        console.log(err);
        this.pageLoading = false;
      }
    },

    async modelDownload() {
      this.pageLoading = true;

      try {
        const response = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/model`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });

        if (process.env.NODE_ENV === 'development') {
          console.log(response);
        }
        this.pageLoading = false;
        this.download(response, 'pkl');
      } catch (err) {
        console.log(err);
        this.pageLoading = false;
      }
    },
    async download(res, extension) {
      if (process.env.NODE_ENV === 'development') {
        console.log(res);
      }
      const url = res.data.results.presignedUrl;
      if (process.env.NODE_ENV === 'development') {
        console.log(url);
      }
      try {
        const response = await axios.get(url, {
          responseType: 'blob',
          headers: {
            "x-ms-blob-type": "BlockBlob",
          },
        });
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${this.datasetId}.${extension}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      }
    },
    async deleteItemExe() {
      this.pageLoading = true;
      this.dialogDeleteDataset = false;
      try {
        const response = await axios.get(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        this.project = response.data.results.projects;
        this.selectedVersion = null;
        if (response.data.results.datasets.length === 0) {
          this.datasets = response.data.results.datasets;
          this.noDataset = true;
          this.pageLoading = false;
          this.selectedItems = [];
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.log(response);
          }
          this.datasets = response.data.results.datasets;
          this.updateDatasetsVersions();
          if (process.env.NODE_ENV === 'development') {
            console.log(this.datasets);
          }
          this.noDataset = false;
          this.datasetId = this.datasets[this.datasets.length - 1].DatasetId;
          this.resetDataset();
          this.calculateTableHeight(1);
          await this.getDatasetInfo(this.datasetId);
        }
      } catch (error) {
        console.log(error);
        this.pageLoading = false;
      }
    },
    async addDatasetEmit(item) {
      try {
        this.pageLoading = true;
        this.datasetId = item.DatasetId;
        this.datasets.push(item);
        this.updateDatasetsVersions();
        this.resetDataset();

        const response = await axios.put(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/project/${this.$route.params.project_id}/dataset/${this.datasetId}/describe`, null, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        });
        if (process.env.NODE_ENV === 'development') {
          console.log(response);
        }
        this.pageLoading = false;
        this.noDataset = false;
        this.deleteFile(0);

        this.pageLoading = true;
        this.hasDatasetPageBeenDisplayed = false;
        this.selectedVersion = null;

        await this.getDatasetInfo(this.datasetId);
        this.scrollToTarget('train');
      } catch (err) {
        console.log(err);
        this.pageLoading = false;
        this.noDataset = false;
      }
    },
  }
};
</script>
 
